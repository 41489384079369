import React from "react";
import { Stack, Typography, Divider } from "@mui/material";
import "./tarif.css";
import { CardTar } from "../customed";

const Tarifs = () => {
  return (
    <>
      <Stack
        id="tarifs"
        padding={{
          xs: "1rem 3rem ",
          sm: "2rem 2rem",
          md: "5rem 7rem 8rem 7rem",
          lg:"2rem 5rem 5rem 5rem"
        }}
        backgroundColor="#ba7538"
        fontWeight="bold"
        flexDirection={"flex"}
      >
        <Divider textAlign="left" >
          <Typography m={1} variant="h6" >
            Nos tarifs
          </Typography>
        </Divider>
        <Stack
          direction={{ xs: "column", sm: "column", md: "row" }}
          spacing={{ xs: 2, sm: 2, md: 2, lg: 4 }}
          margin={{ xs: "0.5rem 1rem", sm: "0.5rem 0rem", md: "2rem 0rem" }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <CardTar className="reveal revealFadeInUp">
            <Stack flexDirection={"row"} justifyContent={'center'}>
              <Stack flexDirection={"column"} mr={2}>
                <Typography fontSize={{xs:18,sm:18,lg:20}}  color={"black"}>
                  Sciage ligne
                </Typography>
                <Typography fontSize={{xs:15,sm:16, lg:18}}align={'center'} color={"white"} sx={{ textShadow: 'black 0.1em 0.1em 0.1em'}}>
                  150 FCFA
                </Typography>
              </Stack>
            </Stack>
          </CardTar>
          <CardTar className="reveal revealFadeInUp">
            <Stack flexDirection={"row"} justifyContent={'center'}>
              <Stack flexDirection={"column"} mr={2}>
                <Typography fontSize={{xs:18,sm:19, lg:20}}  color={"black"}>
                  Rabotage planche
                </Typography>
                <Typography fontSize={{xs:15,sm:16, lg:18}} align={'center'} color={"white"} sx={{ textShadow: 'black 0.1em 0.1em 0.1em'}}>
                  700 FCFA
                </Typography>
              </Stack>
            </Stack>
          </CardTar>
        </Stack>
        <Stack
          direction={{ xs: "column", sm: "column", md: "row" }}
          spacing={{ xs: 2, sm: 2, md: 3, lg: 5 }}
          margin={{ xs: "0.5rem 1rem", sm: "0.5rem 0rem", md: "1rem 0rem" }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <CardTar className="reveal revealFadeInUp">
            <Stack flexDirection={"row"} justifyContent={'center'}>
              <Stack flexDirection={"column"} mr={2}>
                <Typography fontSize={{xs:18,sm:19,lg:20}} color={"black"}>
                  Couvre-joint creu
                </Typography>
                <Typography fontSize={{xs:15,sm:16, lg:18}} align={"center"}  color={"white"} sx={{ textShadow: 'black 0.1em 0.1em 0.1em'}}>
                  700 FCFA
                </Typography>
              </Stack>
            </Stack>
          </CardTar>
          <CardTar className="reveal revealFadeInUp">
            <Stack flexDirection={"row"} justifyContent={'center'}>
              <Stack flexDirection={"column"} mr={2} >
                <Typography fontSize={{xs:18,sm:19, lg:20}} color={"black"}>
                  Gros couvre-joint
                </Typography>
                <Typography fontSize={{xs:15,sm:16, lg:18}} align={"center"}  color={"white"} sx={{ textShadow: 'black 0.1em 0.1em 0.1em'}}>
                  1500 FCFA
                </Typography>
              </Stack>
            </Stack>
          </CardTar>
          <CardTar className="reveal revealFadeInUp">
            <Stack flexDirection={"row"} justifyContent={'center'}>
              <Stack flexDirection={"column"} mr={3}>
                <Typography fontSize={{xs:18,sm:19, lg:20}}  color={"black"}>
                  Couvre-joint plat
                </Typography>
                <Typography  fontSize={{xs:15,sm:16, lg:18}} align={"center"}  color={"white"} sx={{ textShadow: 'black 0.1em 0.1em 0.1em'}}>
                  700 FCFA
                </Typography>
              </Stack>
            </Stack>
          </CardTar>
        </Stack>
        <Stack
          direction={{ xs: "column", sm: "column", md: "row" }}
          spacing={{ xs: 2, sm: 1, md: 3, lg: 5 }}
          margin={{ xs: "0.5rem 1rem", sm: "0.5rem 0rem", md: "1rem 0rem" }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <CardTar className="reveal revealFadeInUp">
            <Stack flexDirection={"row"} justifyContent={'center'}>
              <Stack flexDirection={"column"} mr={2}>
                <Typography fontSize={{xs:18,sm:19,lg:20}} color={"black"}>
                  Porte isoplane
                </Typography>
                <Typography fontSize={{xs:15,sm:16, lg:18}} align={"center"}  color={"white"} sx={{ textShadow: 'black 0.1em 0.1em 0.1em'}}>
                  2000 FCFA
                </Typography>
              </Stack>
            </Stack>
          </CardTar>
          <CardTar className="reveal revealFadeInUp">
            <Stack flexDirection={"row"} justifyContent={'center'}>
              <Stack flexDirection={"column"} mr={2}>
                <Typography fontSize={{xs:18,sm:19, lg:20}} color={"black"}>
                  Porte Z
                </Typography>
                <Typography fontSize={{xs:15,sm:16, lg:18}} align={"center"}  color={"white"} sx={{ textShadow: 'black 0.1em 0.1em 0.1em'}}>
                  1500 FCFA
                </Typography>
              </Stack>
            </Stack>
          </CardTar>
          <CardTar className="reveal revealFadeInUp">
            <Stack flexDirection={"row"} justifyContent={'center'}>
              <Stack flexDirection={"column"} mr={2}>
                <Typography fontSize={{xs:18,sm:19, lg:20}} color={"black"}>
                  Porte en panneaux
                </Typography>
                <Typography fontSize={{xs:15,sm:16, lg:18}} align={"center"}  color={"white"} sx={{ textShadow: 'black 0.1em 0.1em 0.1em'}}>
                  3500 FCFA
                </Typography>
              </Stack>
            </Stack>
          </CardTar>
          <CardTar className="reveal revealFadeInUp">
            <Stack flexDirection={"row"} justifyContent={'center'}>
              <Stack flexDirection={"column"} mr={2}>
                <Typography fontSize={{xs:18,sm:19, lg:20}} color={"black"}>
                  Porte semi-vitrée
                </Typography>
                <Typography fontSize={{xs:15,sm:16, lg:18}} align={"center"}  color={"white"} sx={{ textShadow: 'black 0.1em 0.1em 0.1em'}}>
                  3500 FCFA
                </Typography>
              </Stack>
            </Stack>
          </CardTar>
        </Stack>
        <Stack>
          <Stack
            direction={{ xs: "column", sm: "column", md: "row" }}
            spacing={{ xs: 2, sm: 2, md: 3, lg: 5 }}
            margin={{ xs: "0.5rem 1rem", sm: "0.5rem 0rem", md: "1rem 0rem" }}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CardTar className="reveal revealFadeInUp">
              <Stack flexDirection={"row"} justifyContent={'center'}>
          
                <Stack flexDirection={"column"} mr={2}>
                  <Typography fontSize={{xs:18,sm:19,lg:20}}  color={"black"}>
                    Fenêtre en panneaux
                  </Typography>
                  <Typography fontSize={{xs:15,sm:16, lg:18}} align={"center"} color={"white"} sx={{ textShadow: 'black 0.1em 0.1em 0.1em'}}>
                    2000 FCFA
                  </Typography>
                </Stack>
              </Stack>
            </CardTar>
            <CardTar className="reveal revealFadeInUp">
            
              <Stack flexDirection={"row"} justifyContent={'center'}>
              
                <Stack flexDirection={"column"} mr={2}>
                  <Typography fontSize={{xs:18,sm:19,lg:20}} color={"black"}>
                    Fenêtre Z
                  </Typography>
                  <Typography fontSize={{xs:15,sm:16, lg:18}} align={"center"} color={"white"} sx={{ textShadow: 'black 0.1em 0.1em 0.1em'}}>
                    1000 FCFA
                  </Typography>
                </Stack>
              </Stack>
            </CardTar>
            <CardTar className="reveal revealFadeInUp">
              <Stack flexDirection={"row"} justifyContent={'center'} >
                
                <Stack flexDirection={"column"} mr={2}>
                  <Typography fontSize={{xs:18,sm:19, lg:20}} color={"black"}>
                    Fenêtre semi-vitrée
                  </Typography>
                  <Typography fontSize={{xs:15,sm:16, lg:18}} align={"center"} color={"white"} sx={{ textShadow: 'black 0.1em 0.1em 0.1em'}}>
                    1500 FCFA
                  </Typography>
                </Stack>
              </Stack>
            </CardTar>
          </Stack>
          <Stack
            direction={{ xs: "column", sm: "column", md: "row" }}
            spacing={{ xs: 2, sm: 2, md: 3, lg: 5 }}
            margin={{ xs: "0.5rem 1rem", sm: "0.5rem 0rem", md: "1rem 0rem" }}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CardTar className="reveal revealFadeInUp">
              <Stack flexDirection={"row"} justifyContent={'center'}>  
                <Stack flexDirection={"column"} mr={2}>
                  <Typography fontSize={{xs:18,sm:19, lg:20}} color={"black"}>
                    Garde-linge
                  </Typography>
                  <Typography fontSize={{xs:15,sm:16, lg:18}} align={"center"} color={"white"} sx={{ textShadow: 'black 0.1em 0.1em 0.1em'}}>
                    4500 FCFA
                  </Typography>
                </Stack>
              </Stack>
            </CardTar>
            <CardTar className="reveal revealFadeInUp">
              <Stack flexDirection={"row"} justifyContent={'center'}>
                <Stack flexDirection={"column"} mr={2}>
                  <Typography fontSize={{xs:18,sm:19, lg:20}}  color={"black"}>
                    Meuble de cuisine
                  </Typography>
                  <Typography fontSize={{xs:15,sm:16, lg:18}} align={"center"} color={"white"} sx={{ textShadow: 'black 0.1em 0.1em 0.1em'}}>
                    2000 FCFA
                  </Typography>
                </Stack>
              </Stack>
            </CardTar>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default Tarifs;
