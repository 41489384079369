import React from "react";
import { Box, CardMedia, Stack, Typography } from "@mui/material";
import { Divide } from "../customed";

const Intro = () => {
  return (
    <Stack
      color={"#fff8e7"}
      padding={{ xs: 4, sm:2,md: "5rem 7rem 8rem 7rem", lg:"2rem 5rem 4rem 5rem" }}
      id="intro"
      backgroundColor="#800000"
      fontWeight="bold"
     
    >
      <Divide textAlign="left">
        <Typography variant="h6">A propos de nous</Typography>
      </Divide>
      <Stack
        direction={{ xs: "column", sm: "column", md: "row" }}
        justifyContent={"center"}
        alignItems={"center"}
        alignContent={"center"}
        spacing={{ xs: 3, sm: 1, md: 3, lg: 5 }}
      >
        <Stack
          width={{ xs: 290, sm: 590, md: 700, lg: 940 }}
          height={{ xs: 260, sm: 400, md: 450 }}
          pt={4}
          pb={2}
        >
          <CardMedia
            className="reveal revealRollIn"
            component={"iframe"}
            height={400}
            width={600}
            boxshadow={
              "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px"
            }
            title="Vidéo Yohann Diffusion"
            m={{ xs: 2, sm: "1rem 0rem 0rem 0rem", md: 7 }}
            src="https://www.youtube.com/embed/NdZ0TQCSneM"
            allowFullScreen
          />
        </Stack>
        <Stack
          spacing={{ xs: 1, sm: 4, md: 2, lg: 5 }}
          direction={{ xs: "column", sm: "row", md: "row" }}
          alignItems={"center"}
          width={{ xs: "18.75rem", sm: "35rem", md: "65rem", lg: "55rem" }}
         
        >
          <Box  margin={{ xs: 1}} fontSize={{ xs: 18, sm: 18, md: 18, lg: 21 }}>
            Fondée en 2022, la menuiserie YOHANN DIFFUSION est implantée dans le
            quartier de Kintele à Brazzaville.
            <p>
              Dotée d'équipements de pointe, notre équipe (spécialistes
              qualifiés dans la menuiserie), permet, de la conception à la pose,
              de répondre aux besoins des particuliers et des professionnels
              dans les travaux de menuiserie intérieures et extérieures.
            </p>
          </Box>
          <Box
            className="reveal revealRight"
            justifyContent={"center"}
            alignItems={"center"}
          >
            <a
              style={{ textDecoration: "none" }}
              href="https://calendly.com/yohanndiffusion"
              target="_blank"
              rel="noopener noreferrer"
              aria-label='calendly'
            >
              <Typography
                className="animate__animated animate__tada animate__slow animate__infinite"
                fontSize={{ xs: 11, sm: 11, md: 12.5 }}
                color="#592720"
                boxShadow={
                  "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px"
                }
                fontWeight={"bolder"}
                width={{ xs: "4.8rem", sm: "5rem", md: "6rem" }}
                height={{ xs: "4.8rem", sm: "5rem", md: "6rem" }}
                borderRadius="100%"
                backgroundColor="#FDFBF9"
                textAlign="center"
                p={{ xs: 1.5, sm: 1.4, md: 1.6 }}
                alignItems={"center"}
                justifyContent={"center"}
              >
                 10% de réduction si vous prenez rendez-vous ici!
              </Typography>
            </a>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Intro;
