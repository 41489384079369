import React from "react";
import { Box, Stack, Link, Typography } from "@mui/material";
import logo from "../../../Images/logo-yohann-diffusion.webp";
import { LinkFooter } from "../../customed";
import "./footer.css";

const Footer = () => {
  return (
    <Box
      justifyContent={"center"}
      height={25}
      style={{
        color: "black",
        marginBottom: "0rem",
      }}
    >
      <Stack sx={{ textAlign: "center", alignItems: "center" }}>
        <div
          style={{
            flexDirection: "column",
            margin: "1rem",
          }}
        >
          <img
            alt="Logo Yohann Diffusion"
            src={logo}
            id="logoFooter"
          />
          <Typography fontSize={15} fontWeight={"bold"}>
            Menuiserie Yohann Diffusion
          </Typography>
        </div>
        <Stack
          direction={{ xs: "column", sm: "row", md: "row" }}
          spacing={{ xs: 3, md: 9 }}
          fontWeight={"bold"}
          justifyContent={"center"}
          mt={5}
          mb={5}
        >
          <Link href="/#intro">
            <LinkFooter>Nous connaître</LinkFooter>
          </Link>
          <Link href="/#services">
            <LinkFooter>Nos services</LinkFooter>
          </Link>
          <Link href="/#materiel">
            <LinkFooter>Notre matériel</LinkFooter>
          </Link>
          <Link href="/#contact">
            <LinkFooter>Nous contacter</LinkFooter>
          </Link>
          <Link href="/#tarifs">
            <LinkFooter>Nos tarifs</LinkFooter>
          </Link>
          <Link href="/mentions-legales">
            <LinkFooter>Mentions légales</LinkFooter>
          </Link>
        </Stack>
        <Stack m={2}>
          <Typography fontSize={{ xs: 11, sm: 11, md: 13 }}>
            Made with passion by {" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/in/clémence-ménez/"
              aria-label="Clémence Ménez LinkedIn link"
            >
              Clémence Ménez
            </a>
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Footer;
