import React from "react";
import agencement from "../../Images/ground.webp";
import bois from "../../Images/bois2.webp";
import coupe from "../../Images/coupe.webp";
import mobile_money from "../../Images/mobile.webp";
import sur_mesure from "../../Images/service_sur_mesure.webp";
import livraison_domicile from "../../Images/livraison.webp";
import mobilier from "../../Images/mobilier.webp";
import fenêtre from "../../Images/fenêtre.webp";
import porte from "../../Images/porte.webp";
import vente_bois from "../../Images/vente-bois.webp";
import commandes from "../../Images/commandes.webp";
import useStyles from "../Layout/styles";
import { Box, Stack, Typography, Divider, Container } from "@mui/material";
import { ImageBar, ImgListItem } from "../customed";
import "./services.css";

const Services = () => {

  const classes = useStyles();

  return (
    <Stack
      id="services"
      padding={{
        xs: "1rem",
        sm: "1rem 2rem",
        md: "5rem 7rem 8rem 7rem",
        lg:"2rem 5rem 5rem 5rem"
      }}
      backgroundColor="#FDFBF9"
      fontWeight="bold"
    >
      <Divider textAlign="left">
        <Typography m={1} variant="h6">
          Nos services
        </Typography>
      </Divider>
      <Stack
        display={"flex"}
        direction={{ xs: "column", sm: "row", md: "row" }}
        justifyContent={"center"}
        alignItems={"center"}
        mb={{ xs: 4, sm: 5, md: 5 }}
        spacing={{ sm: 2, md: 3, lg: 8 }}
      >
        <Box
          m={{ xs: 2, sm: 1 }}
          width={{ xs: "18rem", sm: "35rem", md: "70rem", lg: "65rem" }}
          alignItems={{ xs: "center", sm: "center", lg: "center" }}
          justifyContent={{ xs: "center" }}
        >
          <Typography fontSize={{ xs: 17, sm: 18, md: 18, lg: 22 }}>
            Les artisans menuisiers de Yohann Diffusion mettent leur
            savoir-faire et leurs compétences au service de tous vos projets de
            menuiserie. Yohann Diffusion vous accompagne dans vos projets
            d'agencements tels que l'agencement de cuisine, de dressing, de
            salle de bains mais aussi l'aménagement de magasins, de restaurants
            et de bureaux ainsi que pour vos projets de menuiserie extérieure
            (portes, fenêtres) et dans vos travaux de portails et clôtures.
          </Typography>
        </Box>
        <Box className={classes.visible}>
          <img
            id="bois"
            src={bois}
            alt="illustration menuiserie"
            width="auto"
            height="275"
            style={{ borderRadius: "10px 100px / 120px" }}
          />
        </Box>
      </Stack>
      <Container padding={{ xs: 2, sm: 0, md: 1, lg: 0 }}>
        <Stack
          direction={{ xs: "column", sm: "row", md: "row" }}
          spacing={{ xs: 5, sm: 3, md: 4, lg: 3 }}
          margin={{
            xs: "0.1rem 0.25rem",
            sm: "0.25rem 0rem",
            md: "1rem 0rem",
            lg: "3rem 0rem 3rem 0rem",
          }}
          alignItems={"center"}
          justifyContent={"center"}
          className="reveal revealRight"
        >
          <ImgListItem>
            <img
              src={mobilier}
              className="image"
              alt="Mobilier"
              loading="lazy"
            />
            <ImageBar position="below" title="Mobilier" />
          </ImgListItem>
          <ImgListItem>
            <img src={fenêtre} className="image" alt="Portes" loading="lazy" />
            <ImageBar position="below" title="Fenêtres" />
          </ImgListItem>
          <ImgListItem>
            <img src={porte} className="image" alt="Portes" loading="lazy" />
            <ImageBar position="below" title="Portes" />
          </ImgListItem>
          <ImgListItem>
            <img
              src={sur_mesure}
              className="image"
              alt="Sur mesure"
              loading="lazy"
            />
            <ImageBar position="below" title="Service sur mesure" />
          </ImgListItem>
          <ImgListItem>
            <img
              src={agencement}
              className="image"
              alt="Agencement intérieur"
              loading="lazy"
            />
            <ImageBar position="below" title="Agencement intérieur" />
          </ImgListItem>
        </Stack>
      </Container>
      <Container padding={{ xs: 0.5, sm: 1, md: 1, lg: 2 }}>
        <Stack
          direction={{ xs: "column", sm: "row", md: "row" }}
          spacing={{ xs: 5, sm: 3, md: 4, lg: 3 }}
          margin={{
            xs: 5,
            sm: "3rem 0rem",
            md: "3rem 0rem",
            lg: "3rem 0rem 3rem 0rem",
          }}
          alignItems={"center"}
          justifyContent={"center"}
          className="reveal revealLeft"
        >
          <ImgListItem>
            <img
              className="image"
              src={vente_bois}
              loading="lazy"
              alt="vente de bois"
            />
            <ImageBar position="below" title="Vente de bois" />
          </ImgListItem>
          <ImgListItem>
            <img src={coupe} className="image" alt="Découpe" loading="lazy" />
            <ImageBar position="below" title="Découpe immédiate" />
          </ImgListItem>

          <ImgListItem>
            <img
              className="image"
              src={mobile_money}
              loading="lazy"
              alt="mobile money"
            />
            <ImageBar position="below" title="Paiement à distance" />
          </ImgListItem>
          <ImgListItem>
            <img
              src={livraison_domicile}
              className="image"
              alt="Livraison possible"
              loading="lazy"
            />
            <ImageBar position="below" title="Livraison possible" />
          </ImgListItem>
          <ImgListItem>
            <img
              src={commandes}
              className="image"
              alt="Commande de machine possible"
              loading="lazy"
            />
            <ImageBar position="below" title="Commande possible" />
          </ImgListItem>
        </Stack>
      </Container>
    </Stack>
  );
};

export default Services;
