import React, { useState } from "react";
import useStyles from "../Layout/styles";
import {
  PhoneOutlined,
  LocationOnOutlined,
  MailOutlined,
  Twitter,
  Facebook,
  YouTube,
  LinkedIn,
} from "@mui/icons-material";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import axios from "axios";
import "./form.css";
import { SubmitButton, Divide } from "../customed";
import { Box, Alert, AlertTitle, Stack, Typography } from "@mui/material";

const Form = () => {
  const classes = useStyles();
  const defaultValues = {
    lastname: "",
    company: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  };
  const [formValues, setFormValues] = useState(defaultValues);
  const [submitted, setSubmitted] = useState(false);
  const [msg, setMsg] = useState();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    var data = {
      lastname: formValues.lastname,
      company: formValues.company,
      email: formValues.email,
      phone: formValues.phone,
      subject: formValues.subject,
      message: formValues.message,
    };

    const config = {
      headers: { "Content-Type": "application/json" },
    };
    const body = JSON.stringify(data);
    await axios
      .post("https://webandsec.com/api/contact", body, config)
      .then((response) => {
        setSubmitted(true);
        setMsg(response.data.message);
        setFormValues({ ...defaultValues });
      })
      .catch((response) => {
        setSubmitted(false);
        setMsg(response.data.message);
        setFormValues({ ...defaultValues });
      });
  };

  return (
    <Stack
      padding={{
        xs: "1.5rem",
        sm: "1rem 1.5rem",
        md: "2rem 7rem",
        lg: "2rem 5rem",
      }}
      fontSize={{ xs: 0.9, sm: 1.2, md: 1.4 }}
      direction={{ xs: "column", sm: "column" }}
      id="contact"
    >
      <Divide textAlign="left">
        <Typography m={1} variant="h6">
          Pour nous contacter
        </Typography>
      </Divide>
      <Typography
        p={{ xs: "1rem", sm: "1rem", md: "1rem 3rem" }}
        fontSize={{ xs: 18, sm: 18, md: 19, lg: 22 }}
      >
        Pour toute demande de renseignements ou de devis, n'hésitez pas à nous
        contacter par téléphone ou par mail via le formulaire ci-dessous.Vous
        pouvez également prendre directement rendez-vous ici:{" "}
        <span>
          <a
            style={{ color: "white" }}
            target="_blank"
            rel="noopener noreferrer"
            href="https://calendly.com/yohanndiffusion"
            aria-label="calendly link"
          >
            calendly.com/yohanndiffusion
          </a>
        </span>{" "}
        (Réduction de 10% sur toutes les ventes en ce moment si vous prenez
        rendez-vous en ligne)
      </Typography>
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        alignContent="center"
        direction={{ xs: "column", sm: "row", md: "row" }}
        spacing={{ sm: 7, md: 5, lg: 19 }}
        m={{ xs: 3 }}
      >
        <Box
          fontSize={{ xs: 0.9, sm: 1.2, md: 20, lg: 23 }}
          mb={{ xs: 5 }}
          alignItems={"center"}
          className="reveal revealFadeInBottomLeft"
        >
          <Stack alignItems={"center"} m={2} spacing={1} direction="row">
            <div>
              <PhoneOutlined fontSize={"large"} />
            </div>
            <div>
              <Typography
                fontSize={{ lg: 21 }}
                sx={{
                  fontWeight: "bold",
                  alignContent: "center",
                }}
              >
                +242 06 629 86 61
              </Typography>
            </div>
          </Stack>
          <Stack alignItems={"center"} m={2} spacing={1} direction="row">
            <div>
              <MailOutlined fontSize={"large"} />
            </div>
            <div>
              <Typography
                fontSize={{ lg: 21 }}
                sx={{
                  fontWeight: "bold",
                  alignContent: "center",
                }}
              >
                <a
                  style={{ color: "white" }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="mailto:yohanndiffusion242@gmail.com"
                  aria-label="mail"
                >
                  yohanndiffusion242@gmail.com
                </a>
              </Typography>
            </div>
          </Stack>
          <Stack alignItems={"center"} m={2} spacing={1} direction="row">
            <div>
              <LocationOnOutlined fontSize={"large"} />
            </div>
            <div>
              <Typography
                fontSize={{ lg: 21 }}
                sx={{
                  fontWeight: "bold",
                  alignContent: "center",
                }}
              >
                Kintele (Arrêt Mama Mapasa), Brazzaville
              </Typography>
            </div>
          </Stack>
        </Box>
        <Box
          id="contact"
          justifyContent={"center"}
          alignContent={"center"}
          alignItems={"center"}
          width={{ xs: 290, sm: 800, md: 500 }}
        >
          {submitted && (
            <Alert margin={{ xs: "3rem" }} severity="success">
              <AlertTitle>{msg}</AlertTitle>
            </Alert>
          )}
          <>
            <ValidatorForm
              className="reveal revealZoom"
              onSubmit={handleSubmit}
              width={{ xs: "10rem", sm: "12rem", md: "9rem" }}
            >
              <div>
                <TextValidator
                  id="lastname"
                  name="lastname"
                  label="Nom et prénom"
                  margin="normal"
                  fullWidth
                  value={formValues.lastname}
                  onChange={handleInputChange}
                  sx={{ backgroundColor: "white" }}
                  validators={[
                    "required",
                    "matchRegexp:^[a-zA-Z_0-9_' '_,.?@-é/èàêô]{3,50}$",
                  ]}
                  errorMessages={[
                    "Veuillez renseigner votre nom svp.",
                    "Le format n'est pas correct.",
                  ]}
                  color="success"
                  required
                />
                <TextValidator
                  sx={{ backgroundColor: "white" }}
                  id="company"
                  name="company"
                  label="Nom d'entreprise"
                  value={formValues.company}
                  onChange={handleInputChange}
                  validators={[
                    "matchRegexp:^[a-zA-Z_0-9_' '_,.?@-é/èàêô]{3,45}$",
                  ]}
                  errorMessages={["Le format n'est pas correct."]}
                  fullWidth
                  margin="normal"
                  color="success"
                />
              </div>
              <div>
                <TextValidator
                  sx={{ backgroundColor: "white" }}
                  id="email"
                  name="email"
                  type="email"
                  label="Email"
                  margin="normal"
                  color="success"
                  validators={["required", "isEmail"]}
                  errorMessages={[
                    "Veuillez renseigner ce champ svp.",
                    "Votre email n'a pas le bon format.",
                  ]}
                  value={formValues.email}
                  onChange={handleInputChange}
                  fullWidth
                  required
                />
                <TextValidator
                  sx={{ backgroundColor: "white" }}
                  id="phone"
                  name="phone"
                  label="Téléphone"
                  color="success"
                  validators={["matchRegexp:^[0-9]{10,15}$"]}
                  errorMessages={[
                    "Votre numéro de téléphone n'a pas le bon format.",
                  ]}
                  value={formValues.phone}
                  margin="normal"
                  onChange={handleInputChange}
                  fullWidth
                />
              </div>
              <TextValidator
                sx={{ backgroundColor: "white" }}
                id="subject"
                name="subject"
                color="success"
                value={formValues.subject}
                onChange={handleInputChange}
                label="Sujet"
                placeholder="Sujet"
                validators={[
                  "required",
                  "matchRegexp:^[a-zA-Z_0-9_' '_,.?@-é/èàêô]{3,25}$",
                ]}
                errorMessages={[
                  "Veuillez renseigner un sujet svp.",
                  "Le format n'est pas correct.",
                ]}
                margin="normal"
                fullWidth
                required
              />
              <TextValidator
                sx={{ backgroundColor: "white" }}
                id="message"
                name="message"
                value={formValues.message}
                onChange={handleInputChange}
                label="Message"
                margin="dense"
                color="success"
                minRows={5}
                maxRows={5}
                multiline
                validators={["required", "matchRegexp:^[a-zA-Z_0-9_' '\n_,.?@-é/èàêô]{2,1500}$"]}
                errorMessages={[
                  "Veuillez renseigner un message svp.",
                  "Le format n'est pas correct.",
                ]}
                placeholder="Message"
                fullWidth
                required
              />
              <div
                style={{
                  marginTop: 5,
                  display: "flex",
                  flexDirection: "row-reverse",
                }}
              >
                <SubmitButton variant="extended" type="submit" sx={{fontWeight: 'bolder'}}>
                  Envoyer
                </SubmitButton>
              </div>
            </ValidatorForm>
          </>
        </Box>
      </Stack>
      <Stack
        direction={{ xs: "row", sm: "row", md: "row" }}
        spacing={{ xs: 2, sm: 3, md: 4 }}
        padding={{ xs: "2rem", sm: "1rem 2rem" }}
        justifyContent={"center"}
        alignItems={"center"}
        mt={{ md: 2 }}
        mb={{ md: 5 }}
      >
        <a
          className="reveal revealRotate"
          href="https://m.facebook.com/Yohann-Diffusion-Multi-Services-106094912237817/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Facebook link"
        >
          <Facebook fontSize="large" className={classes.facebook} />
        </a>
        <a
          className="reveal revealRotate"
          href="https://twitter.com/YohannDiff"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Twitter link"
        >
          <Twitter fontSize="large" className={classes.twitter} />
        </a>
        <a
          className="reveal revealRotate"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.youtube.com/channel/UCatVSwagFVsPVuhcqdk0SLQ"
          aria-label="Youtube link"
        >
          <YouTube fontSize="large" className={classes.youTube} />
        </a>
        <a
          className="reveal revealRotate"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/company/yohann-diffusion/"
          aria-label="LinkedIn link"
        >
          <LinkedIn fontSize="large" className={classes.linkedIn} />
        </a>
      </Stack>
    </Stack>
  );
};

export default Form;
