import React from "react";
import Header from "./Header/header";
import Footer from "./Footer/footer";
import Navigation from "./Navigation/navigation";
import { StylesProvider } from "@material-ui/core/styles";
import "./layout.css";

const Layout = ({ children }) => {

  const reveal = () => {
    var reveals = document.querySelectorAll(".reveal");
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 200;
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  };

  window.addEventListener("scroll", reveal);

  return (
    <StylesProvider injectFirst>
      <React.Fragment>
        <Header />
        <Navigation />
        <main>{children}</main>
        <Footer />
      </React.Fragment>
    </StylesProvider>
  );
};
export default Layout;
