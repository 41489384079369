import React from "react";
import {
  Stack,
  List,
  ListItem,
  ListItemText,
  Link,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import KeyboardBackspace from "@mui/icons-material/KeyboardBackspaceOutlined";
import { Pap } from "../customed";
import useStyles from "../Layout/styles";
import { fontWeight } from "@mui/system";

const Mentions = () => {
  const classes = useStyles();

  return (
    <Box m={{ xs: 2, sm: 3, md: 6, lg: 5 }}>
      <Pap elevation={5}>
        <Typography className={classes.title} p={3} variant="h5">
          Mentions légales
        </Typography>
        <Stack className={classes.paragraphe}>
          Merci de lire avec attention les différentes modalités d’utilisation
          du présent site avant d’y parcourir ses pages. En vous connectant sur
          ce site, vous acceptez, sans réserves, les présentes modalités. Aussi,
          conformément à l’article n°6 de la Loi n°2004-575 du 21 Juin 2004 pour
          la confiance dans l’économie numérique, les responsables du présent
          site internet <span className="link">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.yohanndiffusion.com"
              aria-label="yohann diffusion link"
            >
              www.yohanndiffusion.com
            </a>
          </span>
          sont :
        </Stack>

        <Stack
          direction={{ xs: "column" }}
          m={{ xs: 2 }}
          className={classes.paragraphe}
        >
          <Typography className={classes.subtitle} variant="h6">
            Éditeur du site:
          </Typography>
          <List>
            <ListItemText style={{fontWeight:'bold'}} >YOHANN DIFFUSION</ListItemText>
            <ListItemText>Responsable éditorial: Yohann Gatse</ListItemText>
            <ListItemText>
              Email:
              <span className="link">
                <a
                  href="mailto:yohanndiffusion242@gmail.com"
                  aria-label="Yohann Diffusion Link"
                >
                  yohanndiffusion242@gmail.com
                </a>
              </span>
            </ListItemText>
            <ListItemText>
              Site Web:
              <span className="link">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.yohanndiffusion.com"
                  aria-label="url"
                >
                  www.yohanndiffusion.com
                </a>
              </span>
            </ListItemText>
          </List>
        </Stack>
        <Stack className={classes.paragraphe}>
          <Typography className={classes.subtitle} variant="h6">
            Hébergement :
          </Typography>
          <List>
            <ListItemText>HOSTINGER INTERNATIONAL LTD</ListItemText>
            <ListItemText>
              61 Lordou Vironos Street, 6023 Larnaca, Chypre
            </ListItemText>
            <ListItemText>
              Site Web:{" "}
              <span className="link">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.hostinger.fr"
                  aria-label="Hostinger link"
                >
                  www.hostinger.fr
                </a>
              </span>
            </ListItemText>
          </List>
        </Stack>
        <Stack className={classes.paragraphe}>
          <Typography className={classes.subtitle} variant="h6">
            Développement :
          </Typography>
          <List>
            <ListItemText>Clémence Ménez</ListItemText>
            <ListItemText>
              Contact:{" "}
              <span className="link">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/in/clémence-ménez/"
                  aria-label="Clémence Ménez LinkedIn link"
                >
                  www.linkedin.com/in/clémence-ménez/
                </a>
              </span>
            </ListItemText>
          </List>
        </Stack>
        <Stack className={classes.paragraphe}>
          <Typography className={classes.subtitle} variant="h6">
            Conditions d’utilisation:
          </Typography>
          <List>
            <ListItem>
              Ce site (www.yohanndiffusion.com) est proposé en différents
              langages web pour un meilleur confort d’utilisation et un
              graphisme plus agréable. Nous vous recommandons de recourir à des
              navigateurs modernes comme Google Chrome, Firefox, Opera, etc… La
              développeuse met en œuvre tous les moyens dont elle dispose, pour
              assurer une information fiable et une mise à jour régulière de ses
              sites internet. Toutefois, des erreurs ou omissions peuvent
              survenir. L’internaute devra donc s’assurer de l’exactitude des
              informations auprès de YOHANN DIFFUSION , et signaler toutes
              modifications du site qu’il jugerait utile. YOHANN DIFFUSION n’est
              en aucun cas responsable de l’utilisation faite de ces
              informations, et de tout préjudice direct ou indirect pouvant en
              découler.
            </ListItem>
            <ListItem>
              Cookies: Toutes les parties de ce site sont fonctionnelles sans
              l'utilisation de cookies.
            </ListItem>
            <ListItem>
              Liens hypertextes : Les sites internet de peuvent offrir des liens
              vers d’autres sites internet ou d’autres ressources disponibles
              sur Internet. YOHANN DIFFUSION ne dispose d’aucun moyen pour
              contrôler les sites en connexion avec ses sites internet. YOHANN
              DIFFUSION ne répond pas de la disponibilité de tels sites et
              sources externes, ni ne la garantit. Elle ne peut être tenue pour
              responsable de tout dommage, de quelque nature que ce soit,
              résultant du contenu de ces sites ou sources externes, et
              notamment des informations, produits ou services qu’ils proposent,
              ou de tout usage qui peut être fait de ces éléments. Les risques
              liés à cette utilisation incombent pleinement à l’internaute, qui
              doit se conformer à leurs conditions d’utilisation.
            </ListItem>
          </List>
        </Stack>
        <Stack className={classes.paragraphe}>
          <Typography className={classes.subtitle} variant="h6">
            Services fournis:
          </Typography>
          <List>
            <ListItem>
              L’ensemble des activités de la société ainsi que ses informations
              sont présentés sur notre site www.yohanndiffusion.com.
            </ListItem>
            <ListItem>
              YOHANN DIFFUSION s’efforce de fournir sur le site
              www.yohanndiffusion.com des informations aussi précises que
              possible.
            </ListItem>
            <ListItem>
              Les renseignements figurant sur le site www.yohanndiffusion.com ne
              sont pas exhaustifs et les photos non contractuelles. Ils sont
              donnés sous réserve de modifications ayant été apportées depuis
              leur mise en ligne. Par ailleurs, tous les informations indiquées
              sur le site sont données à titre indicatif, et sont susceptibles
              de changer ou d’évoluer sans préavis. Limitation contractuelles
              sur les données : Les informations contenues sur ce site sont
              aussi précises que possible et le site remis à jour à différentes
              périodes de l’année, mais peut toutefois contenir des
              inexactitudes ou des omissions. Si vous constatez une lacune,
              erreur ou ce qui parait être un dysfonctionnement, merci de bien
              vouloir le signaler par courriel, à l’adresse
              clemence.mnz@gmail.com, en décrivant le problème de la manière la
              plus précise possible (page posant problème, type d’ordinateur et
              de navigateur utilisé, …). Tout contenu téléchargé se fait aux
              risques et périls de l’utilisateur et sous sa seule
              responsabilité. En conséquence, ne saurait être tenu responsable
              d’un quelconque dommage subi par l’ordinateur de l’utilisateur ou
              d’une quelconque perte de données consécutives au téléchargement.
              De plus, l’utilisateur du site s’engage à accéder au site en
              utilisant un matériel récent, ne contenant pas de virus et avec un
              navigateur de dernière génération mis-à-jour. Les liens
              hypertextes mis en place dans le cadre du présent site internet en
              direction d’autres ressources présentes sur le réseau Internet ne
              sauraient engager la responsabilité de YOHANN DIFFUSION.
            </ListItem>
          </List>
        </Stack>
        <Stack className={classes.paragraphe}>
          <Typography className={classes.subtitle} variant="h6">
            Propriété intellectuelle:
          </Typography>
          <List>
            <ListItem>
              Tout le contenu du présent site, incluant, de façon non
              limitative, les graphismes, images, textes, vidéos, animations,
              sons, logos, gifs et icônes ainsi que leur mise en forme sont la
              propriété exclusive de la société à l’exception des marques, logos
              ou contenus appartenant à d’autres sociétés partenaires ou
              auteurs. Toute reproduction, distribution, modification,
              adaptation, retransmission ou publication, même partielle, de ces
              différents éléments est strictement interdite sans l’accord exprès
              par écrit de YOHANN DIFFUSION. Cette représentation ou
              reproduction, par quelque procédé que ce soit, constitue une
              contrefaçon sanctionnée par les articles L.335-2 et suivants du
              Code de la propriété intellectuelle. Le non-respect de cette
              interdiction constitue une contrefaçon pouvant engager la
              responsabilité civile et pénale du contrefacteur. En outre, les
              propriétaires des Contenus copiés pourraient intenter une action
              en justice à votre encontre.
            </ListItem>
          </List>
        </Stack>
        <Stack className={classes.paragraphe}>
          <Typography className={classes.subtitle} variant="h6">
            Litiges:
          </Typography>
          <List>
            <ListItem>
              Les présentes conditions du site www.yohanndiffusion.com sont
              régies par les lois françaises et toute contestation ou litiges
              qui pourraient naître de l’interprétation ou de l’exécution de
              celles-ci seront de la compétence exclusive des tribunaux dont
              dépend le siège social de la société. La langue de référence, pour
              le règlement de contentieux éventuels, est le français.
            </ListItem>
          </List>
        </Stack>
        <Stack className={classes.paragraphe}>
          <Typography className={classes.subtitle} variant="h6">
            Données personnelles:
          </Typography>
          <List>
            <ListItem>
              De manière générale, vous n’êtes pas tenu de nous communiquer vos
              données personnelles lorsque vous visitez notre site Internet
              www.yohanndiffusion.com. Cependant, ce principe comporte certaines
              exceptions. En effet, pour certains services proposés par notre
              site, vous pouvez être amenés à nous communiquer certaines données
              telles que : votre nom, le nom de votre société, votre adresse
              électronique, et votre numéro de téléphone. Tel est le cas lorsque
              vous remplissez le formulaire qui vous est proposé en ligne, dans
              la rubrique « contact ». Dans tous les cas, vous pouvez refuser de
              fournir vos données personnelles. Dans ce cas, vous ne pourrez pas
              utiliser les services du site, notamment celui de solliciter des
              renseignements sur notre société.
            </ListItem>
          </List>
        </Stack>
        <Stack textAlign={"center"} p={{ xs: "2rem", sm: "2rem", md: "3rem" }}>
          <Typography style={{ color: "grey", fontWeight: "bold" }}>
            <Link style={{ textDecoration: "none" }} href="/">
              <IconButton>
                <KeyboardBackspace />
                Page d'accueil
              </IconButton>
            </Link>
          </Typography>
        </Stack>
      </Pap>
    </Box>
  );
};

export default Mentions;
