import React, { Fragment } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./components/Home/home";
import Mentions from "./components/Mentions/mentions";
import NotFound from "./components/NotFound/notFound";
import Layout from "./components/Layout/layout";

function App() {
  return (
    <BrowserRouter>
      <Fragment>
        <Layout>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/mentions-legales" element={<Mentions />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Layout>
      </Fragment>
    </BrowserRouter>
  );
}

export default App;
