import React from "react";
import logo from "../../../Images/logo-yohann-diffusion.webp";
import { Box, Stack, Link } from "@mui/material";
import { Phone, LocationOn, Mail } from "@mui/icons-material";
import { TypoHeader } from "../../customed";
import "animate.css";
import "animate.css/animate.min.css";
import "./header.css";
import Share from "../../Share/share";

const Header = () => {
  return (
    <Box direction={{ xs: "column", sm: "row", md: "row" }}>
      <Box className="revealLogoLeft" sx={{ float: "left" }}>
        <Link href="/">
          <img id="logo" src={logo} alt="Logo Yohann Diffusion" />
        </Link>
      </Box>
      <Box id="bloc" justifyContent={'center'} sx={{ float: "right" }}>
        <Stack direction="row"  alignItems={"center"}>
          <div className="revealRight">
            <Phone fontSize={"small"} />
          </div>
          <div>
            <TypoHeader>+242 06 629 86 61</TypoHeader>
          </div>
        </Stack>
        <Stack
          direction="row"
          display="flex"
          marginTop={1}
          alignItems={"center"}
        >
          <div className="revealRight" >
            <Mail fontSize={"small"} />
          </div>
          <div>
            <TypoHeader>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:yohanndiffusion242@gmail.com"
              >
               {' '} yohanndiffusion242@gmail.com
              </a>
            </TypoHeader>
          </div>
        </Stack>
        <Stack
          direction="row"
          display="flex"
          marginTop={1}
          alignItems={"center"}
        >
          <div className="revealRight">
            <LocationOn fontSize={"small"} />
          </div>
          <div>
            <TypoHeader>Kintele, Brazzaville</TypoHeader>
          </div>
        </Stack>
        <Share />
      </Box>
    </Box>
  );
};

export default Header;
