import React from "react";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Typography, Menu, Container, IconButton } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import useStyles from "../styles.js";

const Navigation = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const classes = useStyles();

  const Bar = () => (
    <>
      <MenuItem>
        <Typography component="div" sx={{ flexGrow: 1 }}>
          <a
            className={classes.navlink}
            onClick={handleCloseNavMenu}
            href="/#intro"
          >
            Notre entreprise
          </a>
        </Typography>
      </MenuItem>
      <MenuItem>
        <Typography component="div" sx={{ flexGrow: 1 }}>
          <a
            onClick={handleCloseNavMenu}
            className={classes.navlink}
            href="/#services"
          >
            Nos services
          </a>
        </Typography>
      </MenuItem>
      <MenuItem>
        <Typography component="div" sx={{ flexGrow: 1 }}>
          <a
            className={classes.navlink}
            onClick={handleCloseNavMenu}
            href="/#materiel"
          >
            Notre matériel{" "}
          </a>
        </Typography>
      </MenuItem>
      <MenuItem>
        <Typography component="div" sx={{ flexGrow: 1 }}>
          <a
            className={classes.navlink}
            onClick={handleCloseNavMenu}
            href="/#tarifs"
          >
            Nos tarifs
          </a>
        </Typography>
      </MenuItem>
      <MenuItem>
        <Typography component="div" sx={{ flexGrow: 1 }}>
          <a
            className={classes.navlink}
            onClick={handleCloseNavMenu}
            href="/#contact"
          >
            Contact
          </a>
        </Typography>
      </MenuItem>
    </>
  );
  return (
    <AppBar
      position="static"
      style={{
        backgroundColor: "#FDFBF9",
        color:'#000'
      }}
      className={classes.navlink}
    >
      <Container maxWidth="l">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" }
              }}
            >
              <Bar />
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Bar />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navigation;
