import React from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";
import { Stack } from "@mui/material";

const Share = () => {
  return (
    <Stack direction="row" spacing={1} marginTop={2}>
      <FacebookShareButton
        className="rotate"
        quote="Yohann Diffusion Menuiserie"
        url="https://yohanndiffusion.com/"
        hashtag="#menuiserie"
      >
        <FacebookIcon size={30} round={true} />
      </FacebookShareButton>
      <FacebookMessengerShareButton
        className="rotate"
        url="https://yohanndiffusion.com/"
        redirectUri="https://yohanndiffusion.com/"
        appId="487707519453839"
      >
        <FacebookMessengerIcon size={30} round={true} />
      </FacebookMessengerShareButton>
      <WhatsappShareButton
        className="rotate"
        quote="Yohann Diffusion Menuiserie"
        url="https://yohanndiffusion.com/"
      >
        <WhatsappIcon size={30} round={true} />
      </WhatsappShareButton>
      <TwitterShareButton
        className="rotate"
        title="Yohann Diffusion"
        url="https://yohanndiffusion.com/"
      >
        <TwitterIcon size={30} round={true} />
      </TwitterShareButton>
      <EmailShareButton
        className="rotate"
        subject="Yohann Diffusion Menuiserie"
        url="https://yohanndiffusion.com/"
        hashtag="Menuiserie"
      >
        <EmailIcon size={30} round={true} />
      </EmailShareButton>
    </Stack>
  );
};

export default Share;
