import React from "react";
import { Box } from "@mui/material";
import useStyles from "../Layout/styles";
import pageNotFound from "../../Images/page-introuvable.webp";

const NotFound = () => {
  const classes = useStyles();

  return (
    <div className={classes.notFound}>
      <Box justifyContent={"center"}>
        <img className={classes.notFoundImage} src={pageNotFound} alt="Page introuvable" />
      </Box>
    </div>
  );
};

export default NotFound;
